<template>
  <div class="users">
    <Breadcrumb name1="义链宇宙" name2="愿望投票" />
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="queryInfo.title" clearable  placeholder="请输入愿望标题"></el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="queryInfo.data_type" clearable placeholder="请选择投票类型">
            <el-option label="赞" value="1"></el-option>
            <el-option label="踩" value="2"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button icon="el-icon-search" style="margin: 0px;" @click="getDataList"></el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="dataList" stripe style="width: 100%" border>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="title" label="标题">
          <template v-slot="scope">
            {{ scope.row.wish.title }}
          </template>
        </el-table-column>
        <el-table-column prop="user_id" label="用户ID"></el-table-column>
        <el-table-column prop="data_type" label="投票状态" :formatter="dataTypeState"></el-table-column>
        <!-- <el-table-column label="操作" width="230px"> -->
          <!-- <template v-slot="scope"> -->
            <!-- 修改按钮 -->
            <!-- 删除按钮 -->
            <!-- <el-button 
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeById(scope.row.id)"
            ></el-button> -->
          <!-- </template> -->
        <!-- </el-table-column> -->
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        @size-change="handleSizeChange"
        :page-size="queryInfo.page_size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Wish',
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 搜索值
        // query: '',
        // 当前的页数
        page: 1,
        // 当前每次显示多少条数据
        page_size: 10,
        title: '',
        data_type: '',
      },
      // 存放用户的数据和数量
      dataList: [],
      total: 0,
    }
  },
  components: {
    Breadcrumb,
  },
  created() {
    this.getDataList()
  },
  methods: {
    //获取愿望列表
    async getDataList() {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/wish-praise/list',
        params: this.queryInfo,
        timeout: 5000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          this.$message.success('获取愿望投票列表成功!')
          this.dataList = res.data.result.list
          this.total = res.data.result.pager.total_rows
        })
        .catch((res) => {
          this.$message.error('获取愿望投票列表失败!')
        })
    },
    // 监听 page_size 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page_size = newSize
      this.getDataList()
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.page = newPage
      this.getDataList()
    },
    dataTypeState:function(row, index){
      if (row.data_type == 1) {
        return '赞' //根据自己的需求设定
      }else{
        return '踩'
      }
    },
  },
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}
</style>
